import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { IJob, PrimaryNavigation } from '@scanny-app/loopy-loop';
import DataTable from '../../components/Table/DataTable';
import { JOBS_DATA_COLUMNS } from '../../components/Table/Columns';
import { useNavigate } from 'react-router-dom';
import { Image } from 'antd';
interface JobsProps {
  toggleSidebar?: (() => void) | undefined;
  sidebarVisibleInMobile?: boolean | undefined;
}
function Jobs({ toggleSidebar, sidebarVisibleInMobile }: JobsProps) {
  const storedBranch = localStorage.getItem('branch');
  const branch = storedBranch && JSON.parse(storedBranch);

  const navigate = useNavigate();
  const [data, setData] = useState<IJob[]>([]);
  const [apiCompleted, setApiCompleted] = useState(false);
  const [widthMobile, setWidthMobile] = useState<boolean>(window.innerWidth <= 768);
  const COLS = JOBS_DATA_COLUMNS(navigate, widthMobile);

  useEffect(() => {
    setApiCompleted(false);
    if (branch) {
      Auth.currentSession().then((responseData) => {
        const token = responseData.getAccessToken().getJwtToken();
        axios
          .post(
            `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Job`,
            JSON.stringify({
              branchId: branch.id,
            }),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then((response) => {
            setData(response.data as IJob[]);
            setTimeout(() => {
              setApiCompleted(true);
            }, 1000);
          });
      });
    }
  }, [storedBranch]);

  useEffect(() => {
    const handleResize = () => {
      setWidthMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className={`${!storedBranch && 'hidden'} md-flex`}>
        <PrimaryNavigation
          toggleSidebar={toggleSidebar}
          sidebarVisible={sidebarVisibleInMobile}
          menuTitle={branch ? branch.name : 'Jobs'}
          menuIcon={'IconCarRentals'}
        />
      </div>

      {!storedBranch ? (
        <div className={'flex flex-column p-24 gap-20 md-w-fix content-center middle vh-70'}>
          <Image className="md-w-full mb-12" style={{ width: '349px' }} preview={false} src="/assets/zibra-moon.svg"></Image>
          <div className={'flex flex-column gap-12'} style={{ width: '340px' }}>
            <h1 className={'font-semibold text-2xl m-0 text-center text-grey'}>Select your branch</h1>
            <p className={'text-lg text-center m-0 text-grey'}>
              If the branch you&#39;re looking for is not available then contact your <u>administrator</u>
            </p>
          </div>
        </div>
      ) : (
        <div className="container-1 p-24 md-w-fix table-wrapper">
          <DataTable
            columns={COLS}
            data={data}
            apiCompleted={apiCompleted}
            className="md-rental-request"
            getRowKey={(record) => record.id.toString()}
            columnsToSearch={['customerName', 'customerMobile']}
            combinationColumns={[]}
            searchPlaceholder={'Search customer, mobile'}
          />
        </div>
      )}
    </>
  );
}

export default Jobs;
