import { ConfigProvider } from 'antd';
import { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import AppRoutes from './config/AppRoutes';
import { useLocation, Navigate } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { IBranch, BranchContext, LargeLoading, IUser, UserContext, AppConfigContext } from '@scanny-app/loopy-loop';
import axios from 'axios';
import SideMenuList from './config/SideMenuList';
Amplify.configure(awsconfig);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<IUser>({} as IUser);
  const [branchData, setBranchData] = useState<IBranch[]>([]); // [
  const location = useLocation();
  const isSignInPage = location.pathname === '/signin';
  const isHomePage = location.pathname === '/';
  const isAuthenticated = Object.keys(userData).length > 0 && userData.constructor === Object;
  const [appConfig, setAppConfig] = useState({
    logoImagePath: '/assets/zibra-logo.svg',
    SideMenuList: SideMenuList({ branchData }),
    showSideBarText: false,
    sideBarBackgroundColour: '#343434',
  });

  const onIdle = () => {
    if (isAuthenticated) Auth.signOut().then(() => window.location.reload());
  };
  useIdleTimer({
    onIdle,
    timeout: 7200000,
  });
  useEffect(() => {
    document.body.style.setProperty('--global-color', '#3CCEB3');
    Auth.currentAuthenticatedUser()
      .then(() => {
        Auth.currentSession()
          .then((currentSessionResponse) => {
            const token = currentSessionResponse.getAccessToken().getJwtToken();
            setUserData(() => ({
              firstName: currentSessionResponse.getIdToken().payload.given_name,
              lastName: currentSessionResponse.getIdToken().payload.family_name,
              email: currentSessionResponse.getIdToken().payload.email,
              userId: currentSessionResponse.getIdToken().payload['custom:USER_SF_ID'],
            }));
            axios
              .post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Branch`, JSON.stringify({}), {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                setBranchData(() => response.data);
                setAppConfig((prev) => ({
                  ...prev,
                  SideMenuList: SideMenuList({ branchData: response.data }),
                }));
                setIsLoading(false);
              })
              .catch(() => {
                setIsLoading(false);
              });
          })
          .catch(() => {
            setIsLoading(false);
          });
      })
      .catch(() => {
        setIsLoading(false);
        setUserData({} as IUser);
      });
  }, []);
  const theme = {
    colorPrimary: '#3CCEB3',
    fontFamily: 'Inter, sans-serif',
  };
  if (isLoading) return <LargeLoading />;

  if (!isLoading && !isAuthenticated && !isSignInPage) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (!isLoading && isAuthenticated && isSignInPage) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (!isLoading && isAuthenticated && isHomePage) {
    return <Navigate to="/movements" state={{ from: location }} replace />;
  }

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: theme,
        }}
      >
        <Elements stripe={stripePromise}>
          <AppConfigContext.Provider value={appConfig}>
            <UserContext.Provider value={userData}>
              <BranchContext.Provider value={branchData}>
                <AppRoutes></AppRoutes>
              </BranchContext.Provider>
            </UserContext.Provider>
          </AppConfigContext.Provider>
        </Elements>
      </ConfigProvider>
    </div>
  );
}

export default App;
