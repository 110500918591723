import { createContext } from 'react';

export interface IBranch {
  id: string;
  name: string;
  state: string;
  timeZone: string;
  street?: string;
  city?: string;
  postCode?: string;
}
export const BranchContext = createContext<IBranch[]>([]);
