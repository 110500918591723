import { IJob } from '@scanny-app/loopy-loop';
import filterByDate from './FilterDate';

interface FilterDataProps {
  combinationColumns: [string, string][];
  data: IJob[];
  columnsToSearch: string[];
  selectedBranchFilter: string[];
  selectedJobStatusFilter: string[];
  selectedDriverFilter: string[];
  selectedChaserFilter: string[];
  selectedDateFilter: string | null;
  searchInputValue: string;
}

function FilterData({
  combinationColumns,
  data,
  columnsToSearch,
  selectedBranchFilter,
  selectedDateFilter,
  selectedJobStatusFilter,
  selectedDriverFilter,
  selectedChaserFilter,
  searchInputValue,
}: FilterDataProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const datafilter = data.filter((item: any) => {
    let isMatch = true;

    if (selectedBranchFilter.length > 0 && !selectedBranchFilter.includes(item.branchName)) {
      isMatch = false;
    }
    if (selectedJobStatusFilter.length > 0 && !selectedJobStatusFilter.includes(item.status)) {
      isMatch = false;
    }
    if (selectedDriverFilter.length > 0 && !selectedDriverFilter.includes(item.driverId)) {
      isMatch = false;
    }
    if (selectedChaserFilter.length > 0 && !selectedChaserFilter.includes(item.chaserId)) {
      isMatch = false;
    }
    if (selectedDateFilter && !filterByDate(item, selectedDateFilter)) {
      isMatch = false;
    }

    if (isMatch && searchInputValue !== '') {
      const searchMatch = columnsToSearch.some((column: any) =>
        String(item[column]).toLowerCase().includes(searchInputValue.toLowerCase()),
      );

      const combinationMatch = (combinationColumns || []).some((pair: any) => {
        const combinedValue = String(item[pair[0]]) + ' ' + String(item[pair[1]]);
        return combinedValue.toLowerCase().includes(searchInputValue.toLowerCase());
      });

      isMatch = searchMatch || combinationMatch;
    }

    return isMatch;
  });

  return datafilter;
}

export default FilterData;
