const AgreementDescription = () => {
  return (
    <>
      <div style={{ fontSize: '14px', marginTop: '12px', fontWeight: 700 }}>VEHICLE RENTAL AGREEMENT</div>

      <table border={0} className="rental-agreements">
        <tbody>
          <tr>
            <td colSpan={2} style={{ fontWeight: 700 }}>
              IMPORTANT REQUIREMENTS
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              The Hirer must not give or accept any settlement offer, release or admission of liability to any person that affects
              the hire costs and any other money payable under this Agreement, including by signing any document, without prior
              written consent of Carbiz.
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              The Hirer must report any defect in, or damage to, the hire vehicle within 24 hours after its occurrence or
              detection and provide Carbiz with all information and documents reasonably required by Carbiz in relation to that
              defect or damage (including, if applicable, any police report).
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              Should the Hirer fail to comply with any term of this Agreement, all losses and damages suffered by Carbiz arising
              out of such failure will be borne by, and paid for, by the Hirer, except to the extent such loss or damage is caused
              by Carbiz.
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              It is the Hirer’s responsibility to maintain the necessary tyre pressure, engine oils, brake fluids and engine
              coolant levels of the hire vehicle according to the manufacturer's specifications set out in the manual for the Hire
              Vehicle. The Hirer must reimburse Carbiz for any reasonable costs incurred by Carbiz arising out of the Hirer’s
              failure to do so.
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              Should any defect or malfunction of the hire vehicle occur, or if there is any sign of overheating or other fault,
              the Hirer must stop using the vehicle immediately. The Hirer must reimburse Carbiz for any reasonable costs incurred
              by Carbiz arising out of the Hirer’s failure to do so.
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              The Hirer must return the hire vehicle clean and tidy and free of smoking odours, and otherwise in the state of
              repair and condition that this Agreement requires the Hirer to maintain it. The Hirer must reimburse Carbiz for any
              reasonable costs incurred by Carbiz arising out of the Hirer’s failure to do so.
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              The Hirer must pay for all fuel or electric charge (as applicable) required to operate the hire vehicle. The hire
              vehicle must be returned with the same amount of fuel or the electric charge level (as applicable) as at the time of
              handover of the vehicle to the Hirer. If the vehicle is returned with less fuel, Carbiz will charge the difference
              at $3.50 (plus GST) per litre. If the vehicle is returned with a lower electric charge level, Carbiz will charge the
              difference at $0.80 (plus GST) per kWh.
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>1.</td>
            <td style={{ fontWeight: 700 }}>PREAMBLE</td>
          </tr>
          <tr>
            <td>1.1</td>
            <td>
              The agreement between the parties is comprised of this Vehicle Rental Agreement (VRA) and the Mandate and Authority
              to Act (Authority) set out at the end of the VRA (collectively, Agreement).
            </td>
          </tr>
          <tr>
            <td>1.2</td>
            <td>
              In entering into this Agreement, the Hirer warrants that it is the owner or bailee of a vehicle that is unroadworthy
              or in need of repair due to a collision with another vehicle or another incident (Damaged Vehicle). If the Hirer is
              the bailee of the Damaged Vehicle, then the Hirer warrants that it used the Damaged Vehicle with the express
              authority of the owner of that vehicle. The Hirer alleges on reasonable grounds that the collision or incident was
              the fault of a third party, other than as expressly disclosed to, and consented to by, Carbiz.
            </td>
          </tr>
          <tr>
            <td>1.3</td>
            <td>
              The Hirer has requested, and Carbiz has agreed to provide, the vehicle listed on page 1 of this Agreement (Hire
              Vehicle) for the benefit of the Hirer and any additional drivers listed in the Authority or otherwise approved by
              Carbiz in writing (as updated with the prior written consent of Carbiz from time to time) (Additional Drivers).
            </td>
          </tr>
          <tr>
            <td>1.4</td>
            <td>
              The hire period is the period commencing on the start date listed on page 1 of this Agreement and ending on the date
              the Hire Vehicle is returned to Carbiz in accordance with this Agreement (Hire Period).
            </td>
          </tr>
          <tr>
            <td>1.5</td>
            <td>
              The Hirer acknowledges that, subject to the terms of this Agreement, the Hirer is liable for payment of the
              applicable hire costs and any related charges, the amount of which will be calculated by multiplying daily rates for
              various daily charges set out in Rates and Fees set out on page 1 of this Agreement (Rates and Fees) by the number
              of full or part days in the Hire Period and adding to that amount other charges set out in Rates and Fees (Hire
              Costs). The total amount of the Hire Costs will be set out in an invoice issued on return of the Hire Vehicle to
              Carbiz.
            </td>
          </tr>
          <tr>
            <td>1.6</td>
            <td>
              Carbiz will use all reasonable endeavors to recover the Hire Costs in accordance with the Recovery Process (as
              defined in the Authority). However, Carbiz does not guarantee that the Recovery Process will be successful.
            </td>
          </tr>
          <tr>
            <td>1.7</td>
            <td>
              Despite clauses 1.5, 1.6 and 6.1(a), Carbiz agrees to refrain from taking any action to recover all or any part of
              the Hire Costs from the Hirer, except where the Recovery Process has not resulted in Carbiz being able to recover
              the Hire Costs because the Hirer:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>has not provided truthful, accurate and complete information to Carbiz;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>is determined to be the at-fault party in the collision or incident involving the Damaged Vehicle; or</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>failed to comply with any of its obligations under this Agreement.</td>
          </tr>
          <tr>
            <td>1.8</td>
            <td>The Hirer acknowledges that Carbiz has no relationship with any insurer involved in the Recovery Process.</td>
          </tr>
          <tr>
            <td>1.9</td>
            <td>The Hirer acknowledges and agrees that:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              as at the date of this Agreement, the full Hire Costs are unknown and will be based on the length of the Hire
              Period;
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              the Hire Vehicle will only be available to the Hirer for the period of need for a replacement vehicle following the
              collision or incident (that is the period during which the Hirer requires a replacement vehicle for the Hirer’s
              day-to-day activities whilst the repairs to the Damaged Vehicle are being completed or the Damaged Vehicle is being
              determined to be a total loss (as applicable), and the Hirer does not have access to any other vehicles) (Period of
              Need);
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>after the Period of Need, the Hirer must return the Hire Vehicle to Carbiz under clause 9.1; and</td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>
              the total amount of the Hire Costs may be different to any estimate provided once the actual Hire Period is known.
            </td>
          </tr>
          <tr>
            <td>1.10</td>
            <td>
              If contact, licence or credit card details of the Hirer or any Additional Drivers change during the Hire Period,
              then the Hirer must promptly provide Carbiz with updated details.
            </td>
          </tr>
          <tr>
            <td>1.11</td>
            <td>
              The Hirer's rights in this Agreement are in addition to any rights the Hirer has under any laws that cannot be
              excluded, restricted or modified by agreement of the parties, including any applicable rights under Schedule 2 of
              the Competition and Consumer Act 2010 (Cth) (Non-Excludable Rights).
            </td>
          </tr>
          <tr>
            <td>1.12</td>
            <td>
              The parties acknowledge and agree that the National Credit Code contained in the National Consumer Credit Protection
              Act 2009 (Cth) does not apply to this Agreement.
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>2.</td>
            <td style={{ fontWeight: 700 }}>CARBIZ AND HIRE VEHICLE</td>
          </tr>
          <tr>
            <td>2.1</td>
            <td>Carbiz:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              enters into this Agreement in its own right, or as an agent of, or with the authority from, or otherwise on behalf
              of another person or entity who has the legal title to the Hire Vehicle (as applicable); and
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>represents that it has the right to enter into this Agreement.</td>
          </tr>
          <tr>
            <td>2.2</td>
            <td>The Hirer hires the Hire Vehicle pursuant to the terms set out in this Agreement.</td>
          </tr>
          <tr>
            <td>2.3</td>
            <td>
              If Carbiz replaces the Hire Vehicle hired under this Agreement with a substitute vehicle, including without
              limitation under clause 4.11, then:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              the terms of this Agreement will apply to that substitute vehicle as if the details of that substitute vehicle were
              set out in this Agreement; and
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              the parties will be bound by this Agreement as far as it relates to the hire of that substitute vehicle for the
              period of hire of that vehicle.
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>3.</td>
            <td style={{ fontWeight: 700 }}>HIRER’S WARRANTY</td>
          </tr>
          <tr>
            <td>3.1</td>
            <td>The Hirer warrants that:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>it is physically able to, and legally qualified to, operate the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>it is the owner or bailee of the Damaged Vehicle;</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              it has been deprived of the use of the Damaged Vehicle as a result of a collision with another vehicle or another
              incident;
            </td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>
              it has suffered both physical inconvenience from the inability to use the Damaged Vehicle and loss of amenity of the
              use of the Damaged Vehicle;
            </td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>
              it cannot conduct its day-to-day activities without a vehicle, does not have access to any other vehicles and
              therefore will need a replacement vehicle for the time when the Damaged Vehicle is in need of repair or is being
              assessed as a total loss (as applicable);
            </td>
          </tr>
          <tr>
            <td>(f)</td>
            <td>
              it has provided truthful, accurate and complete information to Carbiz, and has given Carbiz all information that it
              knows or should have reasonably known that would be relevant to Carbiz’s decision to hire a vehicle to the Hirer;
              and
            </td>
          </tr>
          <tr>
            <td>(g)</td>
            <td>
              it has not, and will not, make any admission of liability in respect to the collision or incident involving the
              Damaged Vehicle without the prior written consent of Carbiz (acting reasonably), except where such admission of
              liability has already been disclosed to Carbiz on or before the date of this Agreement.
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>4.</td>
            <td style={{ fontWeight: 700 }}>USE OF HIRE VEHICLE</td>
          </tr>
          <tr>
            <td>4.1</td>
            <td>
              The Hirer must maintain the Hire Vehicle in the state of repair and condition in which it was given to the Hirer
              having regard to the damage check sheet on page 1 of this Agreement (Damage Check Sheet) and must ensure that the
              Hire Vehicle is kept reasonably clean and tidy and free of smoking odours.
            </td>
          </tr>
          <tr>
            <td>4.2</td>
            <td>
              The Hirer must keep the Hire Vehicle locked when not in use and must engage any security device fitted or supplied
              with the Hire Vehicle. It is the Hirer's responsibility to protect all personal property in the Hire Vehicle from
              loss or theft, and Carbiz will not be liable to the Hirer for any such loss or theft.
            </td>
          </tr>
          <tr>
            <td>4.3</td>
            <td>
              The Hirer must use all reasonable endeavours to protect the Hire Vehicle against bad weather that can cause damage
              to the Hire Vehicle, including hail, and keep the Hire Vehicle garaged whenever possible.
            </td>
          </tr>
          <tr>
            <td>4.4</td>
            <td>
              The Hirer must maintain the Hire Vehicle's tyre pressure, engine oils, brake fluid and engine coolant levels at the
              proper operating levels according to the manufacturer's specifications set out in the manual for the Hire Vehicle.
            </td>
          </tr>
          <tr>
            <td>4.5</td>
            <td>
              The Hirer must supply all fuel or electric charge for the Hire Vehicle during the Hire Period. Except where the Hire
              Vehicle is an electric vehicle, the Hirer must ensure that it refuels the Hire Vehicle with the fuel that is
              appropriate for that vehicle (as noted on the key tag for the Hire Vehicle supplied by Carbiz).
            </td>
          </tr>
          <tr>
            <td>4.6</td>
            <td>
              The Hirer must not, and must not permit anyone to, repair, or perform any work on, the Hire Vehicle, except with the
              prior written consent of Carbiz.
            </td>
          </tr>
          <tr>
            <td>4.7</td>
            <td>
              Only the Hirer and Additional Drivers are permitted to operate the Hire Vehicle, provided that such persons comply
              with the requirements of this Agreement and all applicable laws.
            </td>
          </tr>
          <tr>
            <td>4.8</td>
            <td>
              Where the Hirer or an Additional Driver is aged under 25 years or has held a driver's licence for less than 2 years,
              the Hirer will be liable to pay an inexperienced driver fee as set out in the Rates and Fees.
            </td>
          </tr>
          <tr>
            <td>4.9</td>
            <td>
              Despite any other term or provision of this Agreement, none of the following persons may operate the Hire Vehicle at
              any time:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              a person other than the Hirer or an Additional Driver set out in this Agreement, except with the prior written
              consent of Carbiz;
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>a person who holds a learner licence;</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              a person who does not hold a current motor vehicle driver’s licence for the particular class of the Hire Vehicle;
            </td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>
              a person whose breath or blood alcohol concentration exceeds the maximum lawful concentration or who is intoxicated
              or who is under the influence of any drug, toxic, or illegal substance;
            </td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>
              a person who has given Carbiz, or for whom the Hirer has given Carbiz, a false name, age, address or driver’s
              licence details; or
            </td>
          </tr>
          <tr>
            <td>(f)</td>
            <td>a person whose driver’s licence has been cancelled, endorsed or suspended within the last 3 months.</td>
          </tr>
          <tr>
            <td>4.10</td>
            <td>The Hirer must not, and must ensure that any Additional Driver does not:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              use or operate the Hire Vehicle outside of the State that it was hired in without the prior written consent of
              Carbiz;
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              drive the Hire vehicle on any unsealed road or in any off-road conditions, including without limitation fire trails,
              snow, beaches, sand, tracks, rails (other than as cargo), underground mines, mining shafts, tunnels which are not
              public roads, fields and paddocks;
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              carry any inflammable, explosive or corrosive materials or carry any animal or pet in the Hire Vehicle, excluding
              guide dogs;
            </td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>carry passengers in the Hire Vehicle for hire or rideshare purposes;</td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>carry passengers in excess of the capacity of the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(f)</td>
            <td>carry passengers other than in the interior or cab of the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(g)</td>
            <td>
              push, propel or tow another vehicle, trailer or any other object using the Hire Vehicle without prior written
              consent of Carbiz;
            </td>
          </tr>
          <tr>
            <td>(h)</td>
            <td>
              use the Hire Vehicle for or in any race, competition, rally, speed or any other trial, contest, motor sport or any
              driver training course or in preparation for any of them;
            </td>
          </tr>
          <tr>
            <td>(i)</td>
            <td>
              use or operate the Hire Vehicle in a reckless or negligent manner or in a way that causes any loss or damage to any
              person or property;
            </td>
          </tr>
          <tr>
            <td>(j)</td>
            <td>
              use or operate the Hire Vehicle whilst the Hirer knows, or should reasonably know, that it is in an unsafe or
              unroadworthy condition;
            </td>
          </tr>
          <tr>
            <td>(k)</td>
            <td>act fraudulently or dishonestly;</td>
          </tr>
          <tr>
            <td>(l)</td>
            <td>smoke in the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(m)</td>
            <td>cause malicious damage to the Hire Vehicle; or</td>
          </tr>
          <tr>
            <td>(n)</td>
            <td>
              use or operate the Hire Vehicle in any way that does not comply with all applicable laws and regulations, including
              without limitation any seat belt and child restraint laws, and conditions of that person’s driver’s licence.
            </td>
          </tr>
          <tr>
            <td>4.11</td>
            <td>
              The Hirer must inform Carbiz of any damage to, or defect in, the Hire Vehicle within 24 hours of its occurrence or
              detection and must provide Carbiz with all information reasonably required by Carbiz in connection with the damage
              or defect (including, if applicable, any police report). After receiving notice of the damage or defect, Carbiz may
              require the Hirer to return the Hire Vehicle to Carbiz. If Carbiz determines, acting reasonably, that the Hire
              Vehicle is damaged or defective, Carbiz must, subject to any other rights it may have under this Agreement, provide
              the Hirer with a replacement vehicle, which, if accepted by the Hirer, will be substituted as the Hire Vehicle for
              the purposes of this Agreement.
            </td>
          </tr>
          <tr>
            <td>4.12</td>
            <td>
              Carbiz will be responsible for any reasonable transportation or moving costs associated with any return of the Hire
              Vehicle under clause 4.11. However, subject to any Non-Excludable Rights, the Hirer must reimburse such costs if
              Carbiz determines, acting reasonably, on inspection of the Hire Vehicle, that the Hire Vehicle is not damaged or
              defective, or that the damage or defect has been caused by the Hirer.
            </td>
          </tr>
          <tr>
            <td>4.13</td>
            <td>
              The Hirer must not sell, transfer, lease or dispose of the Hire Vehicle or any of its parts or give anyone any legal
              or beneficial rights, title or interest in the Hire Vehicle.
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>5.</td>
            <td style={{ fontWeight: 700 }}>LIABILITY FOR LOSS OR DAMAGE</td>
          </tr>
          <tr>
            <td>5.1</td>
            <td>
              Subject to any Non-Excludable Rights, the Hirer is liable to compensate Carbiz for all loss or damage to the Hire
              Vehicle and will be liable for any and all loss, damage or injury to any third party arising out of the use or
              operation of the Hire Vehicle during the Hire Period, where the liability arises in connection with:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>tyre damage, including punctures and tread that is worn down beyond fair wear and tear;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>headlight, roof or underbody damage;</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>water damage caused by the immersion of the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>interior damage to the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>loss of the key to the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(f)</td>
            <td>key being left in or near the Hire Vehicle whilst it was unattended;</td>
          </tr>
          <tr>
            <td>(g)</td>
            <td>
              the Hirer or any Authorised Driver leaving the scene of a collision or incident before exchanging details with all
              involved parties, except where the Hirer or any Authorised Driver is reasonably prevented from exchanging such
              details because the collision or incident has resulted in any passenger or driver of the Hire Vehicle having to
              attend hospital or engage with law enforcement or any other emergency services;
            </td>
          </tr>
          <tr>
            <td>(h)</td>
            <td>any deliberate, dangerous or reckless conduct by the Hirer and any Authorised Driver; or</td>
          </tr>
          <tr>
            <td>(i)</td>
            <td>the Hirer otherwise breaching this Agreement (including without limitation clause 4).</td>
          </tr>
          <tr>
            <td></td>
            <td>
              If this clause 5.1 applies, then within 7 days after Carbiz's written demand, the Hirer must pay Carbiz the amount
              of such loss or damage.
            </td>
          </tr>
          <tr>
            <td>5.2</td>
            <td>
              Subject to any Non-Excludable Rights, the Hirer is liable to pay excess to Carbiz in the amount set out in clause
              5.4 (Excess) where any loss or damage to the Hire Vehicle during the Hire Period arises in connection with any cause
              other than set out in clause 5.1. If this clause 5.2 applies, then within 7 days after Carbiz’s written demand, the
              Hirer must pay Carbiz the amount of the applicable Excess.
            </td>
          </tr>
          <tr>
            <td>5.3</td>
            <td>Where the Excess is payable by the Hirer under this Agreement:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              subject to clause 5.3(b), the Excess will be reduced to the amount set out under clause 5.4 if the Hirer has paid
              the excess reduction fee (if any, as set out in the Rates and Fees);
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              the Excess will not be reduced (unless Carbiz, in its discretion, agrees otherwise) if the Hire Vehicle was involved
              in two or more incidents during the Hire Period resulting in loss or damage; and
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              the amount of the Excess (whether reduced under clause 5.3(a) or not) will be refunded to the Hirer if the third
              party or its insurer pays the cost of the relevant loss or damage in full.
            </td>
          </tr>
          <tr>
            <td>5.4</td>
            <td>
              The applicable Excess and reduced Excess (if an excess reduction fee is paid) payable by the Hirer are as follows:
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <table border={1} className="table-collapse">
                <tbody>
                  <tr style={{ fontWeight: 700 }}>
                    <td>Selected excess reduction option</td>
                    <td> Excess (without excess reduction fee)</td>
                    <td> Excess (if excess reduction fee is paid)</td>
                  </tr>
                  <tr>
                    <td>1. Basic excess (only applicable where row 2 of this table does not apply)</td>
                    <td> $7,500.00 (plus GST)</td>
                    <td> $0.00 (plus GST)</td>
                  </tr>
                  <tr>
                    <td>
                      2. Overseas driver’s licence holder excess, which is payable when the Hirer or any Additional Driver holds
                      an overseas driver’s licence and does not hold an Australian driver’s licence
                    </td>
                    <td> $7,500.00 (plus GST)</td>
                    <td> $2,000.00 (plus GST)</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>5.5</td>
            <td>
              The Hirer will not be liable under clauses 5.1 to 5.4 to the extent the relevant loss or damage is caused or
              contributed to by Carbiz, including as a result of Carbiz's failure to properly maintain the Hire Vehicle or its
              negligence or breach of this Agreement.
            </td>
          </tr>
          <tr>
            <td>5.6</td>
            <td>
              The Hirer acknowledges that it is a third-party beneficiary under Carbiz's insurance policy and that Carbiz does not
              provide comprehensive insurance directly to the Hirer.
            </td>
          </tr>
          <tr>
            <td>5.7</td>
            <td>
              Subject to the terms of this Agreement (including without limitation this clause 5), and to the full extent
              permitted by law, the Hirer releases Carbiz from, and indemnifies Carbiz against, any costs, expenses, fees,
              charges, losses, damages, liability, claims, demands, suits or proceedings incurred or suffered by, or made against,
              Carbiz in connection with the use or operation of the Hire Vehicle during the Hire Period or the Hirer’s breach of
              this Agreement.
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>6.</td>
            <td style={{ fontWeight: 700 }}>PAYMENT OF HIRE COSTS AND OTHER AMOUNTS</td>
          </tr>
          <tr>
            <td>6.1</td>
            <td>Within 1 day after the date of Carbiz’s invoice or demand, the Hirer must pay to Carbiz the following costs:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>the Hire Costs;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>any amounts that Carbiz determines, acting reasonably, are owed by the Hirer under clause 5;</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              any other amount payable by the Hirer under this Agreement for the Hire Period, including without limitation any
              amount payable by the Hirer under clause 7; and
            </td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>
              interest on any amount that remains unpaid after the 7th day after their respective due date for payment. Such
              interest is calculated at the rate of 2% per annum about the average of the most recent prime rate, indicator rate,
              or reference rate (however described) for business overdrafts published by the Commonwealth Bank of Australia, on
              daily rests.
            </td>
          </tr>
          <tr>
            <td>6.2</td>
            <td>
              The Hirer authorises any debit or credit card or any other card or account provided by the Hirer (Hirer’s Card) to
              be securely stored in stripe.com platform or a similar platform in accordance Carbiz’s Privacy Policy.
            </td>
          </tr>
          <tr>
            <td>6.3</td>
            <td>The Hirer authorises Carbiz to:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              charge the Hirer’s Card with any Hire Costs or other amounts due and payable by the Hirer under this Agreement; and
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              put an authorisation hold on the Hirer’s Card in the amount of $1.00 for a period of 7 days from the available
              balance of the Hirer’s Card as security for the Hirer’s performance of its obligations under this Agreement.
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>7.</td>
            <td style={{ fontWeight: 700 }}>TOLLS AND INFRINGEMENTS</td>
          </tr>
          <tr>
            <td>7.1</td>
            <td>
              The Hirer is responsible for payment of all tolls, parking and traffic infringements in the Hire Vehicle for the
              Hire Period.
            </td>
          </tr>
          <tr>
            <td>7.2</td>
            <td>
              If Carbiz receives a toll, infringement or similar notice for the Hire Period, it will nominate the Hirer as the
              person responsible for payment of the relevant notice. If Carbiz is unable, for any reason, to make such nomination,
              then in addition to the amount of that toll or infringement, Carbiz may charge the Hirer an administration fee of:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>$15.00 (plus GST) for a toll notice; and</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>$50.00 (plus GST) for an infringement notice.</td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>8.</td>
            <td style={{ fontWeight: 700 }}>GST</td>
          </tr>
          <tr>
            <td>8.1</td>
            <td>
              In this Agreement the terms defined in A New Tax System (Goods and Services Tax) Act 1999 (Cth) (GST Act) have the
              same meaning as in that legislation.
            </td>
          </tr>
          <tr>
            <td>8.2</td>
            <td>The Hirer acknowledges that:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              the Hire Costs and any fees, charges and other payments referred to in this Agreement (Consideration) do not include
              any GST imposed under the GST Act; and
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              the Hirer must pay or reimburse Carbiz for any GST and indemnify Carbiz for any GST in addition to the Consideration
              at the same time as the Consideration is payable by the Hirer.
            </td>
          </tr>
          <tr>
            <td>8.3</td>
            <td>
              Carbiz will provide the Hirer with a tax invoice before the Hirer is required to pay or reimburses the GST to
              Carbiz.
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>9.</td>
            <td style={{ fontWeight: 700 }}>RETURN OF HIRE VEHICLE</td>
          </tr>
          <tr>
            <td>9.1</td>
            <td>
              The Hirer must return the Hire Vehicle to Carbiz to a location agreed to by Carbiz (which must be within 60
              kilometers of a Carbiz branch) at the following time:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              if the Damaged Vehicle is assessed to be repairable, within 24 hours after the Hirer is notified of the conclusion
              of the assessment and repair process;
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>if the Damaged Vehicle is determined to be a total loss, within 24 hours after settlement funds are received;</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>if this Agreement is terminated under this Agreement for any reason, on the effective date of termination; or</td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>as otherwise required by this Agreement or agreed between the parties in writing.</td>
          </tr>
          <tr>
            <td>9.2</td>
            <td>
              The Hire Vehicle must be returned to Carbiz clean and tidy and free of smoking odours, and otherwise in the state of
              repair and condition in which it was given to the Hirer, having regard to the Damage Check Sheet, including with all
              of the Hire Vehicle’s original components and accessories. The Hirer must reimburse Carbiz for any reasonable costs
              incurred by Carbiz arising out of the Hirer’s failure to do so. Without limiting these obligation, the Hirer is
              liable for cleaning costs and replacement costs of any missing components, including without limitation any SD card,
              vehicle key, tools, jacking equipment, spare tyres, telematics, roof racks, baby seats and electronic vehicle
              charging cables.
            </td>
          </tr>
          <tr>
            <td>9.3</td>
            <td>
              The Hirer must return Hire Vehicle with the same amount of fuel or electric charge level (as applicable) as at the
              time of handover of the Hire Vehicle to the Hirer. If the Hire Vehicle is returned with less:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>fuel, Carbiz will charge the Hirer the difference at $3.50 (plus GST) per litre; or</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>electric charge level, Carbiz will charge the difference at $0.80 (plus GST) per kWh.</td>
          </tr>
          <tr>
            <td>9.4</td>
            <td>
              If the Hirer fails to return the Hire Vehicle to Carbiz by the return date specified under clause 9.1, then in
              addition to its other rights under this Agreement, Carbiz may:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>report the Hire Vehicle as having been stolen to the police; and</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              without notice, enter into any premises where it suspects the Hire Vehicle is located and remove it without
              committing a trespass. The Hirer irrevocably authorises Carbiz to enter into such premises and indemnifies Carbiz
              against all loss or damage suffered or incurred by Carbiz as a result, except to the extent caused by Carbiz's gross
              negligence.
            </td>
          </tr>
          <tr>
            <td>9.5</td>
            <td>Carbiz may take possession of the Hire Vehicle without prior demand if:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>the Hire Vehicle is illegally parked;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              Carbiz reasonably determines that the Hire Vehicle is being used in contravention of any law or in breach of this
              Agreement;
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              Carbiz reasonably determines that the Hire Vehicle has been damaged or is defective in any way, and such damage or
              defect has not been reported by the Hirer to Carbiz as required under this Agreement;
            </td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>Carbiz reasonably determines that the Hire Vehicle has been abandoned; or</td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>
              Carbiz becomes aware of speeding by any person driving the Hire Vehicle by more than 20 km/h above the speed limit.
            </td>
          </tr>
          <tr>
            <td>9.6</td>
            <td>
              Once the Hire Vehicle is returned by the Hirer, Carbiz will use its reasonable endeavours to carry out a final
              inspection of the Hire Vehicle as follows:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>if the Hire Vehicle is returned within 8am - 6pm on a business day, then at the time of the return; and</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              if the Hire Vehicle is returned outside of the above hours of operation, then within 8am - 6pm on the next business
              day after the return.
            </td>
          </tr>
          <tr>
            <td>9.7</td>
            <td>
              Carbiz will use its reasonable endeavours to confirm the condition of the Hire Vehicle to the Hirer within 4
              business hours of the final inspection if Carbiz does not consider that the Hire Vehicle was returned in the state
              of repair and condition required by this Agreement.
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>10.</td>
            <td style={{ fontWeight: 700 }}>BREACH AND TERMINATION</td>
          </tr>
          <tr>
            <td>10.1</td>
            <td>
              In addition to its rights under clause 9.5, Carbiz may terminate this Agreement by giving the Hirer at least 48
              hours' written notice if:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>the Hirer breaches this Agreement and, in Carbiz's reasonable opinion, the breach:</td>
          </tr>
          <tr>
            <td>(i)</td>
            <td>cannot be remedied; or</td>
          </tr>
          <tr>
            <td>(ii)</td>
            <td>
              can be remedied, but is not remedied within 48 hours after Carbiz gives written notice of the breach to the Hirer;
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              the Hirer is, in Carbiz's reasonable opinion, responsible for delaying or hindering the Recovery Process, including
              where the Hirer fails to provide any necessary information or documentation within a reasonable time;
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              the Hirer has, in Carbiz's reasonable opinion, intentionally, recklessly or negligently mislead Carbiz when
              providing information about the collision or incident involving the Damaged Vehicle;
            </td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>
              Carbiz reasonably determines, despite its reasonable efforts in relation to the Recovery Process, that the Hire
              Costs will not be fully or partially recoverable from the alleged at-fault party, the alleged at-fault party's
              insurer or the Hirer's insurer (as applicable).
            </td>
          </tr>
          <tr>
            <td>10.2</td>
            <td>
              Despite any other term or provision of this Agreement, either party may terminate this Agreement at any time for any
              reason by giving the other party at least 48 hours’ written notice.
            </td>
          </tr>
          <tr>
            <td>10.3</td>
            <td>On the date of termination of this Agreement for any reason, the Hirer must:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>return the Hire Vehicle to Carbiz in accordance with all requirements under this Agreement; and</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>pay all money due and payable under this Agreement up to and including the date of termination.</td>
          </tr>
          <tr>
            <td>10.4</td>
            <td>
              If the Hirer is in default in payment of any amount owed to Carbiz under this Agreement, the Hirer authorises Carbiz
              to provide information of that default to a credit reporting body and to obtain an up-to-date consumer credit report
              on the Hirer. The Hirer acknowledges and agrees that Personal information may be used and disclosed by Carbiz to the
              credit reporting body and vice versa in accordance with the Privacy Act 1988 (Cth) to create or maintain a credit
              information file containing information about the Hirer, including defaults under this Agreement and the debt owed
              by the Hirer to Carbiz.
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>11.</td>
            <td style={{ fontWeight: 700 }}>ONBOARD DEVICES</td>
          </tr>
          <tr>
            <td>11.1</td>
            <td>By signing this Agreement, the Hirer:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              authorises Carbiz to install in the Hire Vehicle and use any Onboard Device for any purpose required by Carbiz;
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              acknowledges and agrees that Carbiz owns all rights, title and interest (including any intellectual property rights)
              in the recordings, videos, materials and any other output produced by the Onboard Devices; and
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              authorises Carbiz to use those recordings, videos, materials and output for any purposes required by Carbiz
              (including sales and marketing).
            </td>
          </tr>
          <tr>
            <td>11.2</td>
            <td>
              For the purpose of clause 11.1, the term “Onboard Device” means a GPS, immobiliser, driving recorder, event data
              recorder, onboard camera, sensor, computer or another device that is installed in the Hire Vehicle that has one or
              more of the following functions:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>electronic tracking;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>immobilising;</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>recording;</td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>monitoring;</td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>viewing; or</td>
          </tr>
          <tr>
            <td>(f)</td>
            <td>any other function reasonably required by Carbiz.</td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>12.</td>
            <td style={{ fontWeight: 700 }}>JURISDICTION</td>
          </tr>
          <tr>
            <td>12.1</td>
            <td>This Agreement is governed by the laws of the State in which the Hire Vehicle was hired.</td>
          </tr>

          <tr>
            <td style={{ fontWeight: 700 }}>13.</td>
            <td style={{ fontWeight: 700 }}>PRIVACY POLICY</td>
          </tr>
          <tr>
            <td>13.1</td>
            <td>
              The Hirer authorises Carbiz to collect and use any personal information provided by the Hirer in accordance with
              Carbiz’s Privacy Policy.
            </td>
          </tr>
        </tbody>
      </table>

      <div style={{ fontSize: '14px', marginTop: '12px', fontWeight: 700 }}>MANDATE AND AUTHORITY TO ACT</div>
      <table className="rental-agreements" border={0}>
        <tbody>
          <tr>
            <td colSpan={2} style={{ fontWeight: 700 }}>
              RECOVERY OF COSTS
            </td>
          </tr>
          <tr>
            <td>1.</td>
            <td>
              The Hirer irrevocably appoints Carbiz as the Hirer’s agent, representative and attorney to recover, by whatever
              means, including commencement and carrying on of legal proceedings in the name of the Hirer, the Hire Costs from the
              alleged at-fault party, the alleged at-fault party's insurer or the Hirer's insurer (as applicable) and to receive
              any such amounts and to account for them as Carbiz deems appropriate (Recovery Process).
            </td>
          </tr>
          <tr>
            <td>2.</td>
            <td>
              Carbiz will use its reasonable endeavours to carry out the Recovery Process. This may include, but does not require,
              Carbiz commencing and carrying on legal proceedings in the name of the Hirer.
            </td>
          </tr>
          <tr>
            <td>3.</td>
            <td>
              The Hirer must provide Carbiz with truthful, accurate and complete information and documentation reasonably required
              for Carbiz to carry out the Recovery Process on the Hirer’s behalf, including registration and insurance documents
              relating to the Damaged Vehicle, driver’s licences of the Hirer, all Additional Drivers, the owner of the Damaged
              Vehicle and the driver of the Damaged Vehicle at the time of the collision or incident (if the owner and the driver
              are different to the Hirer).
            </td>
          </tr>
          <tr>
            <td>4.</td>
            <td>
              The Hirer acknowledges and agrees that Carbiz is entitled to appoint and give instructions on behalf of the Hirer to
              recovery agents, solicitors, barristers and other advisers (collectively, Advisers) in connection with the Recovery
              Process. The Hirer agrees to provide all reasonable assistance to Carbiz and the Advisers, and render all
              cooperation reasonably required by Carbiz and the Advisers, in respect of the Recovery Process. The Hirer
              acknowledges that this may require that the Hirer does all reasonably necessary things, provides and signs all
              reasonably necessary statements and documents and attends the court to give evidence as the plaintiff or a witness
              (as applicable). The Hirer must cause the owner of the Damaged Vehicle and the driver of the Damaged Vehicle at the
              time of the collision or incident (if the owner and the driver are different to the Hirer) to comply with the
              requirements of this clause 4 as if that person was the Hirer.
            </td>
          </tr>
          <tr>
            <td>5.</td>
            <td>
              The Hirer must not give any offer, promise of payment, settlement, waiver, release, indemnity or admission of
              liability to any person in connection with the Hire Costs and any other money payable under, or any other matter
              arising out of, this Agreement, including by signing any document, without prior written consent of Carbiz. Without
              limiting the Hirer’s obligations under this clause 6, the Hirer must not in any way delay or hinder the Recovery
              Process and must not release or purport to release the alleged at-fault party, the alleged at-fault party's insurer
              or the Hirer's insurer (as applicable) from any of the Hirer’s rights in respect to the collision or incident, the
              Hire Costs and other money payable under this Agreement, including by signing a form of release or otherwise.
            </td>
          </tr>
          <tr>
            <td>6.</td>
            <td>
              In consideration of Carbiz agreeing to comply with its obligations under this Agreement (including provision of the
              Hire Vehicle to the Hirer), the Hirer agrees to its obligations under clauses 1 to 5 of this Authority and
              acknowledges that they are reasonably necessary to enable Carbiz to carry out the Recovery Process and to protect
              Carbiz’s interest under this Agreement.
            </td>
          </tr>

          <tr>
            <td colSpan={2} style={{ fontWeight: 700 }}>
              DAMAGED VEHICLE AND MITIGATION OF LOSS
            </td>
          </tr>
          <tr>
            <td>7.</td>
            <td>
              The Hirer agrees to notify Carbiz if it becomes aware that the Damaged Vehicle has been moved from one location or
              repairer to another location or repairer.
            </td>
          </tr>
          <tr>
            <td>8.</td>
            <td>
              The Hirer acknowledges that Carbiz is an independent company and does not represent any insurer or repairer. The
              Hirer acknowledges that Carbiz does not organise or coordinate, nor is it liable or responsible for, the repair,
              storage, movement or disposal of the Damaged Vehicle.
            </td>
          </tr>
          <tr>
            <td>9.</td>
            <td>
              Within 24 hours of receiving the relevant notification, correspondence or payment from the insurer (as applicable),
              the Hirer must:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              notify Carbiz of any information or documents that indicate that an insurer has determined that the Damaged Vehicle
              is a total loss;
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              give Carbiz a copy of any settlement notice or correspondence notifying the Hirer as to when the settlement funds
              are intended to be paid to the Hirer; and
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>notify Carbiz that the settlement funds have been received.</td>
          </tr>
          <tr>
            <td>10.</td>
            <td>
              Within 24 hours after receiving notification that the repairs to the Damaged Vehicle are completed or receiving the
              total loss settlement funds (as applicable), the Hirer must contact Carbiz and make arrangements with Carbiz for the
              return of the Hire Vehicle.
            </td>
          </tr>
          <tr>
            <td>11.</td>
            <td>The Hirer acknowledges that:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              it has an obligation to mitigate its loss by ensuring that the Hire Vehicle is hired only for the Period of Need;
              and
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              for that purpose, during and after the Hire Period, Carbiz may contact the Hirer, the Hirer’s repairer and any
              relevant insurer in connection with the status of repairs to the Damaged Vehicle or determination of the Damaged
              Vehicle to be a total loss (as applicable).
            </td>
          </tr>
          <tr>
            <td>12.</td>
            <td>
              The Hirer acknowledges and agrees that failure to promptly return the Hire Vehicle may result in the Hirer being
              liable for further Hire Charges for each day after the end of the period referred to in clause 10 of this Authority,
              as Carbiz may not be able to recover them as part of the Recovery Process.
            </td>
          </tr>

          <tr>
            <td colSpan={2} style={{ fontWeight: 700 }}>
              SURVIVAL
            </td>
          </tr>
          <tr>
            <td>13.</td>
            <td>
              Without limiting or impacting on the continued operation of any clause of this Agreement that as a matter of
              construction is intended to survive the termination or expiry of this Agreement, the following clauses survive the
              termination or expiry of this Agreement:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>clauses 1, 2, 3, 4.11, 4.12, 4.13, 5, 6, 7, 8, 9, 10.4 and 12 of the VRA; and</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>clauses 1, 2, 3, 4, 5, 6, 9, 10, 11 and 12 of this Authority.</td>
          </tr>

          <tr>
            <td colSpan={2} style={{ fontWeight: 700 }}>
              DECLARATION AND AUTHORITY
            </td>
          </tr>
          <tr>
            <td>14.</td>
            <td>
              The Hirer declares and authorises Carbiz, whether by itself, its legal advisers or recovery agents appointed by it
              in its discretion to:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>act on the Hirer’s behalf in respect of the Recovery Process;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              obtain all documents, reports and other materials reasonably required by Carbiz or its legal advisers or recovery
              agents in respect of the Recovery Process;
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              receive payment of any recovered amounts to Carbiz’s account or Carbiz’s trust account (including by cheque or
              electronic funds transfer), which, regardless of payee of such payment, can be utilised and applied to discharge any
              part of the Hire Costs or any other outstanding amounts payable by the Hirer to Carbiz under this Agreement;
            </td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>
              transfer any of the recovered amounts from Carbiz’s trust account into Carbiz’s account or any other account Carbiz
              requires; and
            </td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>
              share the Hirer’s personal information with any third parties to the extent necessary for the purpose of the
              Recovery Process.
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default AgreementDescription;
