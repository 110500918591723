import { useNavigate } from 'react-router-dom';
import { DefaultCard, EmptyCard, IJob, CustomButton, MyIcon } from '@scanny-app/loopy-loop';

interface OverviewVehicleHandoverProps {
  navigateURL: string;
  job: IJob;
  needsVehicleHandover: boolean;
}

function OverviewVehicleHandover({ navigateURL, job, needsVehicleHandover }: OverviewVehicleHandoverProps) {
  const navigate = useNavigate();

  return (
    <div>
      {needsVehicleHandover && !job.vehicleId ? (
        <DefaultCard
          className={'icon-sm'}
          content={
            <>
              <MyIcon icon={'IconPending'} />
              Waiting for Carbiz to assign a vehicle...
            </>
          }
        />
      ) : needsVehicleHandover ? (
        <CustomButton
          className={'on-hire-lg w-full content-center h-45'}
          onClick={() => navigate(navigateURL)}
          btnName={'Vehicle Handover'}
          iconName={'IconPreAuth'}
        />
      ) : job.recordTypeName.includes('Delivery') && job.bookingRecordType === 'Direct Hire' && !needsVehicleHandover ? (
        <DefaultCard
          className={'content-space-between'}
          content={
            <>
              Vehicle Handover Completed
              <MyIcon icon={'IconPreAuth'} />
            </>
          }
        />
      ) : (
        <EmptyCard />
      )}
    </div>
  );
}

export default OverviewVehicleHandover;
