import { IBranch, MyIcon, ISideMenu, ISubMenu } from '@scanny-app/loopy-loop';
interface SideMenuProps {
  branchData: IBranch[];
}
function SideMenuList({ branchData }: SideMenuProps) {
  const branchSubMenu: ISubMenu[] = branchData
    .map((branch) => ({
      label: branch.name,
      slug: branch.name,
      link: '/movements',
      group: branch.state,
      id: branch.id,
    }))
    .sort((a, b) => {
      if (a.group < b.group) {
        return -1;
      }
      if (a.group > b.group) {
        return 1;
      }
      return 0;
    });

  const data: ISideMenu[] = [
    {
      label: 'Branches',
      slug: 'movements',
      icon: <MyIcon icon="IconCarRentals" />,
      menu: branchSubMenu,
      link: '/movements',
      activeSubMenuFormula: () => {
        let branch = localStorage.getItem('branch');
        if (branch) {
          return JSON.parse(branch).name;
        } else {
          return '';
        }
      },
      onSubMenuClickAction: (subMenuItem: ISubMenu) => {
        const id = subMenuItem.id;
        const name = subMenuItem.label;
        localStorage.setItem('branch', JSON.stringify({ id: id, name: name }));

        const event = new CustomEvent('storage', { detail: { key: 'branch', newValue: JSON.stringify({ id: id, name: name }) } });
        window.dispatchEvent(event);
      },
    },
  ];

  return data;
}

export default SideMenuList;
