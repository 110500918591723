export const getOffset = (timeZone: string): string => {
  const timeZoneName = Intl.DateTimeFormat('en-US', {
    timeZoneName: 'short',
    timeZone,
  })
    .formatToParts()
    .find((i) => i.type === 'timeZoneName')?.value;

  if (!timeZoneName) {
    throw new Error(`Could not find timeZoneName for timeZone: ${timeZone}`);
  }

  const offset = timeZoneName.slice(3);
  if (!offset) return '+00:00';

  const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
  if (!matchData) throw new Error(`cannot parse timezone name: ${timeZoneName}`);

  const [, sign, hour, minute] = matchData;
  const hours = parseInt(hour, 10).toString().padStart(2, '0');
  const minutes = (minute ? parseInt(minute, 10) : 0).toString().padStart(2, '0');

  return `${sign}${hours}:${minutes}`;
};
