/** This function takes in a datetime string input and returns an object consisting of a string with the day of the week and a string in the format of day month year.
 * This is to display datetime from the database in a user-friendly way.**/
const formatDate = (datetime: string) => {
  if (!datetime) return { weekday: '', dayMonthYear: '' };
  const date = new Date(datetime);
  const dayMonthYear = date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
  const weekday = date.toLocaleDateString('en-GB', { weekday: 'long' });
  return { weekday, dayMonthYear };
};
export { formatDate };
