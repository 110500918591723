import { Input, message, Select } from 'antd';
import FilterData from '../../helpers/FilterData';
import getFilterConfig, { InitialFilters } from '../../helpers/GetFilterConfig';
import { useEffect, useState } from 'react';
import { IJob, ISelectOption, filterOptions, GetOptionsFromDB } from '@scanny-app/loopy-loop';

interface FilterTableProps {
  dataSource: IJob[];
  onFilterChange: (data: []) => void;
  combinationColumns: [string, string][];
  columnsToSearch: string[];
  searchPlaceholder: string;
  onHideChange: (isFilterVisible: boolean) => void;
}
function FilterTable({
  dataSource,
  onFilterChange,
  combinationColumns,
  columnsToSearch,
  searchPlaceholder,
  onHideChange,
}: FilterTableProps) {
  const storedBranch = localStorage.getItem('branch');
  const storedDate = localStorage.getItem('dateFilter') ?? 'THIS_WEEK';
  const branchInfo = JSON.parse(storedBranch as string);
  const { Option } = Select;
  const [filteredData, setFilteredData] = useState<IJob[]>([]);
  const [filterState, setFilterState] = useState(getFilterConfig(branchInfo ? branchInfo.name : null));
  const filteredDataFunction = FilterData({
    columnsToSearch: columnsToSearch,
    combinationColumns: combinationColumns,
    data: dataSource,
    searchInputValue: filterState.searchTerm.value as string,
    selectedBranchFilter: filterState.branchFilter.value as string[],
    selectedDateFilter: storedDate,
    selectedJobStatusFilter: filterState.jobStatusFilter.value as string[],
    selectedDriverFilter: filterState.driverFilter.value as string[],
    selectedChaserFilter: filterState.chaserFilter.value as string[],
  });

  const [userOptions, setUserOptions] = useState<ISelectOption[]>([]);
  // const userData = useContext(UserContext);
  const [isFilterHidden, setIsFilterHidden] = useState(false);

  useEffect(() => {
    const fetchUserOptions = async () => {
      if (userOptions.length === 0) {
        try {
          const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/User`;
          const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
            {
              endpoint: url,
              filter: {},
            },
            (item) => `${item.name}`,
          );
          const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
          setUserOptions(sortedOptions);
        } catch (error) {
          message.error('Something went wrong. Please try again.');
        }
      }
    };

    fetchUserOptions();
  }, []);

  const handleFilterChange = (newValue: string[] | string, filterName: keyof InitialFilters) => {
    if (filterName === 'dateFilter') {
      if (newValue === undefined) {
        localStorage.removeItem('dateFilter');
      } else {
        const stringValue = Array.isArray(newValue) ? JSON.stringify(newValue) : newValue.toString();
        localStorage.setItem('dateFilter', stringValue);
      }
    }

    setFilterState((prevState: InitialFilters) => ({
      ...prevState,
      [filterName]: {
        ...prevState[filterName],
        value: newValue,
      },
    }));
  };
  const handleFilter = () => {
    setIsFilterHidden(!isFilterHidden);
  };
  const handleReset = () => {
    setFilterState(getFilterConfig(branchInfo ? branchInfo.name : null));
    localStorage.removeItem('dateFilter');
  };

  useEffect(() => {
    setFilterState(getFilterConfig(branchInfo ? branchInfo.name : null));
  }, [branchInfo.name, dataSource]);

  useEffect(() => {
    setFilteredData(filteredDataFunction);
  }, [filterState]);

  useEffect(() => {
    onFilterChange(filteredData as []);
  }, [filteredData]);
  useEffect(() => {
    onHideChange(isFilterHidden);
  }, [isFilterHidden]);

  const jobStatusFilter = [
    { label: 'Pending', value: 'Pending' },
    { label: 'In Transit', value: 'In Transit' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Client Late', value: 'Client Late' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Postponed', value: 'Postponed' },
  ];

  const dateFilter = [
    { label: 'Today', value: 'TODAY' },
    { label: 'Tomorrow', value: 'TOMORROW' },
    { label: 'Yesterday', value: 'YESTERDAY' },
    { label: 'This Week', value: 'THIS_WEEK' },
    { label: 'Last Week', value: 'LAST_WEEK' },
    { label: 'Next Week', value: 'NEXT_WEEK' },
    { label: 'This Month', value: 'THIS_MONTH' },
    { label: 'Last Month', value: 'LAST_MONTH' },
    { label: 'Next Month', value: 'NEXT_MONTH' },
  ];

  return (
    <div className="flex gap-16 mb-16 lg-flex-column tb-rental-request-filter">
      <div className={`flex gap-16 w-60 lg-w-full lg-flex-column content-space-between ${isFilterHidden ? 'lg-hidden' : ''}`}>
        <div className="search-field w-full" data-name="Date Filter">
          <Select
            className="w-full individual-search"
            placeholder="Date"
            value={storedDate}
            onChange={(value) => handleFilterChange(value, 'dateFilter')}
            allowClear
          >
            {dateFilter.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="search-field w-full " data-name="Driver">
          <Select
            value={filterState.driverFilter.value}
            onChange={(value) => handleFilterChange(value, 'driverFilter')}
            filterOption={filterOptions}
            className="w-full booking-status-filter"
            placeholder="Driver"
            mode="multiple"
            showSearch
            allowClear
          >
            {userOptions.map((option: ISelectOption) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="search-field w-full " data-name="Chaser">
          <Select
            value={filterState.chaserFilter.value}
            onChange={(value) => handleFilterChange(value, 'chaserFilter')}
            filterOption={filterOptions}
            className="w-full booking-status-filter"
            placeholder="Chaser"
            mode="multiple"
            showSearch
            allowClear
          >
            {userOptions.map((option: ISelectOption) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="search-field w-full " data-name="Booking Status">
          <Select
            value={filterState.jobStatusFilter.value}
            onChange={(value) => handleFilterChange(value, 'jobStatusFilter')}
            className="w-full booking-status-filter"
            placeholder="Job Status"
            mode="multiple"
            allowClear
          >
            {jobStatusFilter.map((item, index) => (
              <Option key={`${item.value}-${index}`} value={item.value || ''}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="flex gap-16 w-40 lg-w-full">
        <div className="search-field w-full" data-name="Search">
          <Input
            placeholder={searchPlaceholder}
            onChange={(event) => handleFilterChange(event.target.value as string, 'searchTerm')}
            value={filterState.searchTerm.value}
            className={'search-field w-full'}
            allowClear
          />
        </div>
        <div className="flex gap-8">
          <button className="btn-filter" onClick={handleFilter}>
            {isFilterHidden ? 'Filter' : 'Hide'}
          </button>
          <button
            className="btn-reset"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default FilterTable;
