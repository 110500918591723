import { Form, Input, Col, Row, Select, DatePicker } from 'antd';
import { BranchContext, getTimes, filterOptions, IJob, ISelectOption, GetOptionsFromDB, IBooking } from '@scanny-app/loopy-loop';
import { useState, useContext } from 'react';
import { OverviewRentalAgreement, timeZoneOptions, getOffset } from '@scanny-app/loopy-loop';
import dayjs from 'dayjs';
import OverviewVehicleHandover from '../../components/Card/OverviewVehicleHandover';

interface JobDetailsProps {
  jobData: IJob;
  bookingData: IBooking;
  componentDisabled: boolean;
  handleDataChange: (formData: IJob) => void;
}
const { Option } = Select;

function JobDetails({ jobData, bookingData, componentDisabled, handleDataChange }: JobDetailsProps) {
  const [form] = Form.useForm();
  const [userOptions, setUserOptions] = useState<ISelectOption[]>([]);
  const [vehicleOptions, setVehicleOptions] = useState<ISelectOption[]>([]);
  const [updatedFields, setUpdatedFields] = useState({});
  const branchData = useContext(BranchContext);
  const branchOptions: ISelectOption[] = branchData.map((branch) => ({
    label: branch.name,
    value: branch.id,
  }));
  const currBranch = branchData.find((branch) => branch.id === jobData.branchId);
  const getUsers = async () => {
    if (userOptions.length === 0) {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/User`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: {},
        },
        (item) => `${item.name}`,
      );
      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setUserOptions(sortedOptions);
    }
  };
  const getVehicles = async () => {
    if (vehicleOptions.length === 0) {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/ReplacementVehicles`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: {
            branchId: jobData.branchId,
          },
        },
        (item) => `${item.name} - ${item.makeName} ${item.modelName} - ${item.branchName}`,
      );

      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setVehicleOptions(sortedOptions);
    }
  };
  const handleChange = (fieldName: string, value: string) => {
    if (fieldName === 'jobTime' || fieldName === 'jobDate' || fieldName === 'timeZone') {
      fieldName = 'dueDate';
      value = `${dayjs(form.getFieldValue('jobDate')).format('YYYY-MM-DD')}T${form.getFieldValue('jobTime').slice(0, 5)}:00.000000${getOffset(form.getFieldValue('timeZone'))}`;
    }
    handleDataChange({ ...updatedFields, [fieldName]: value } as IJob);
    setUpdatedFields(() => ({ ...updatedFields, [fieldName]: value }));
  };

  return (
    <div className="container-2 p-24 md-w-fix gap-24">
      <h5 className="m-0 text-sm">Rental Agreement</h5>
      <OverviewRentalAgreement
        navigateURL={`/rentals/rental-agreement/${jobData.id}/${jobData.bookingId}`}
        booking={bookingData}
        customerFirstName={jobData.customerName}
        customerLastName={''}
        needsRentalAgreement={jobData.bookingRentalAgreement === null}
      />
      <h5 className="m-0 text-sm">Vehicle Handover</h5>
      <OverviewVehicleHandover
        job={jobData}
        needsVehicleHandover={
          jobData.recordTypeName.includes('Delivery') &&
          jobData.bookingRecordType === 'Direct Hire' &&
          !jobData?.vehicleHandoverId
        }
        navigateURL={`/vehicle-handover/${jobData.id}`}
      ></OverviewVehicleHandover>
      <Form
        id="form-data"
        className="w-full form-style-one"
        layout="vertical"
        form={form}
        initialValues={{ ...jobData, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <h5 className="m-0 text-sm">Job Details</h5>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item name="recordTypeName" label="Type">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item name="status" label="Status">
              <Select
                disabled={jobData.status === 'Completed' ? true : componentDisabled}
                onSelect={(value) => handleChange('status', value)}
              >
                <Option value="Pending">Pending</Option>
                <Option value="In Transit">In Transit</Option>
                <Option value="Completed">Completed</Option>
                <Option value="Client Late">Client Late</Option>
                <Option value="Cancelled">Cancelled</Option>
                <Option value="Postponed">Postponed</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xs={24}>
            <Form.Item name="jobAddress" label="Address">
              <Input onChange={(e) => handleChange('jobAddress', e.target.value)} disabled={componentDisabled} />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item name="jobDate" label="Date">
              <DatePicker
                onChange={(e) => {
                  if (e) {
                    handleChange('jobDate', e.format('YYYY-MM-DD'));
                  } else {
                    handleChange('jobDate', '');
                  }
                }}
                className="box"
                disabled={componentDisabled}
                format={'DD/MM/YYYY'}
              />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item name="jobTime" label="Time">
              <Select disabled={componentDisabled} onSelect={(value) => handleChange('jobTime', value)} showSearch>
                {getTimes(false, 15).map((option: ISelectOption) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item name="timeZone" label="Time Zone">
              <Select disabled={componentDisabled} onSelect={(value) => handleChange('timeZone', value)} showSearch>
                {timeZoneOptions.map((option: ISelectOption) => (
                  <Option key={option.value} value={option.value}>
                    {option.label.replace('Australia/', '').replace('_', ' ')}{' '}
                    {Intl.DateTimeFormat().resolvedOptions().timeZone === option.label ? '(Your time zone)' : ''}
                    {option.label === currBranch?.timeZone ? '(Their timezone)' : ''}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item name="vehicleName" label="Vehicle">
              <Select
                disabled={componentDisabled}
                filterOption={filterOptions}
                showSearch
                onFocus={getVehicles}
                onClick={getVehicles}
                onSelect={(value) => handleChange('vehicleId', value)}
              >
                {vehicleOptions.length === 0 ? (
                  <Option key={'Loading...'} value={'Loading...'} disabled>
                    Loading...
                  </Option>
                ) : (
                  vehicleOptions.map((option: ISelectOption) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item name="branchName" label="Branch">
              <Select
                disabled={componentDisabled}
                filterOption={filterOptions}
                onSelect={(value) => handleChange('branchId', value)}
                showSearch
              >
                {branchOptions.map((option: ISelectOption) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item name="driverName" label="Driver">
              <Select
                disabled={componentDisabled}
                filterOption={filterOptions}
                showSearch
                onFocus={getUsers}
                onClick={getUsers}
                onSelect={(value) => handleChange('driverId', value)}
              >
                {userOptions.length === 0 ? (
                  <Option key={'Loading...'} value={'Loading...'} disabled>
                    Loading...
                  </Option>
                ) : (
                  userOptions.map((option: ISelectOption) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item name="chaserName" label="Chaser">
              <Select
                disabled={componentDisabled}
                filterOption={filterOptions}
                showSearch
                onFocus={getUsers}
                onClick={getUsers}
                onSelect={(value) => handleChange('chaserId', value)}
              >
                {userOptions.length === 0 ? (
                  <Option key={'Loading...'} value={'Loading...'} disabled>
                    Loading...
                  </Option>
                ) : (
                  userOptions.map((option: ISelectOption) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <h5 className="m-0 text-sm">Customer Details</h5>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item name="customerName" label="Customer">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item name="customerMobile" label="Mobile">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col lg={24} xs={24}>
            <Form.Item name="jobNotes" label="Notes">
              <Input.TextArea onChange={(e) => handleChange('jobNotes', e.target.value)} rows={4} disabled={componentDisabled} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default JobDetails;
