import { Button, Checkbox, Form, Input, message, Spin } from 'antd';
import { capitalizeWords, IJob, SecondaryNavigation, LargeLoading } from '@scanny-app/loopy-loop';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';

interface VehicleHandoverProps {
  isVisible: boolean;
  AgreementDescription: React.ReactNode;
}
const VehicleHandover = ({ isVisible, AgreementDescription }: VehicleHandoverProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const [jobData, setJobData] = useState<IJob>({} as IJob);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSignatureExist, setIsSignatureExist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    Auth.currentSession().then((responseData) => {
      const token = responseData.getAccessToken().getJwtToken();
      axios
        .post(
          `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Job`,
          JSON.stringify({
            id: id,
          }),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          setJobData(response.data[0] as IJob);
          setIsLoading(false);
        });
    });
  }, []);

  const handleEndDraw = () => {
    if (signatureRef && signatureRef.current) {
      const signatureDataURL = signatureRef.current?.toDataURL();
      form.setFieldsValue({ signature: signatureDataURL });
      setIsSignatureExist(true);
    }
  };
  const clearSignature = () => {
    signatureRef.current?.clear();
    form.setFieldsValue({ signature: '' });
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(capitalizeWords(e.target.value));
  };
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(capitalizeWords(e.target.value));
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        Auth.currentSession().then((responseData) => {
          const token = responseData.getAccessToken().getJwtToken();
          axios
            .post(
              `${process.env.REACT_APP_ENDPOINT_URL}/create/VehicleHandover`,
              JSON.stringify({
                id: id,
                bookingId: jobData.bookingId,
                firstName: firstName,
                lastName: lastName,
                mobilePhone: values.mobilePhone,
                email: values.email,
                agreeToTerms: values.agreeToTerms,
                signature: values.signature,
              }),
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            .then((response) => {
              setIsLoading(false);
              navigate(`/movements/view/${id}`);
            });
        });
      })
      .catch((info) => {
        message.error('Validation Failed');
      });
  };

  return (
    <>
      <SecondaryNavigation menuTitle={'Vehicle Handover'} onBack={() => navigate(`/movements/view/${id}`)} />
      <div className="container-2 md-w-fix">
        <div className="flex gap-16 flex-column">
          {isLoading && <LargeLoading />}
          <Form className="w-full form-style-one flex gap-16 flex-column" layout="vertical" form={form} disabled={isLoading}>
            <div className="gap-16 py-24 grid grid-cols-2 md-grid-cols-1">
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the first name',
                  },
                ]}
              >
                <Input onChange={handleFirstNameChange} className="capitalize" />
              </Form.Item>
              <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'Please enter the last name' }]}>
                <Input onChange={handleLastNameChange} className="capitalize" />
              </Form.Item>

              <Form.Item
                name="mobilePhone"
                label="Mobile Phone"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the mobile number',
                  },
                  {
                    pattern: /^(?:\(?(?:\+?61|0)4\)?(?:[ -]?[0-9]){7})[0-9]$/,
                    message: 'Please enter a valid mobile number',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter the email' }]}>
                <Input type="email" />
              </Form.Item>
            </div>
            <Form.Item className="box-1 p-12 agreement-desc">{AgreementDescription}</Form.Item>

            <Form.Item
              className="box-1 px-12 py-4"
              name="agreeToTerms"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: '',
                },
                {
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('You must agree to the terms'))),
                },
              ]}
            >
              <Checkbox className="font-normal">
                <span className="required-checkbox-label">I agree to the terms and conditions</span>
              </Checkbox>
            </Form.Item>
            <Form.Item
              className="box-1 px-12 py-4"
              name="acceptOnBehalf"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: '',
                },
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('You must agree on behalf of the dealersip')),
                },
              ]}
            >
              <Checkbox className="font-normal">
                <span className="required-checkbox-label">
                  I hereby accept on behalf of <strong>{jobData?.hireAccount}</strong> vehicle{' '}
                  <strong>
                    {jobData.vehicleName} - {jobData.vehicleModelName}
                  </strong>
                </span>
              </Checkbox>
            </Form.Item>
            <Form.Item label="Signature" name="signature" rules={[{ required: true, message: 'Please provide your signature.' }]}>
              {(isVisible || isSignatureExist) && (
                <SignatureCanvas
                  ref={signatureRef}
                  onEnd={handleEndDraw}
                  canvasProps={{
                    width: 277,
                    height: 140,
                    className: 'box-1',
                  }}
                />
              )}
            </Form.Item>

            <Button onClick={clearSignature} className="cust-btn" style={{ width: '150px' }}>
              Clear Signature
            </Button>
            <div className={'flex gap-12 content-end border-t-dashed py-12 sticky-banner'}>
              {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
              <button type="button" onClick={handleSubmit} className="btn-submit align-middle gap-16 flex " disabled={isLoading}>
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default VehicleHandover;
