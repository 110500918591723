import { Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  NotFound,
  SignIn,
  AppContent,
  CreateRentalAgreement,
  AgreementComplete,
  KAUAgreementDescription,
} from '@scanny-app/loopy-loop';
import Jobs from '../pages/Movements/Jobs';
import JobDetails from '../pages/SinglePages/ViewRecord';
import AgreementDescription from './AgreementDescription';
import VehicleHandover from '../components/Form/SubForm/VehicleHandover';

const pageTitles: { [key: string]: string } = {
  '*': 'Not Found',
  '/something-went-wrong': '500',
  '/movements': 'Movements',
  '/signin': 'Sign In',
};

function AppRoutes() {
  const location = useLocation();
  useEffect(() => {
    let title = 'Zibra';
    for (const key in pageTitles) {
      if (location.pathname.includes(key)) {
        title = pageTitles[key];
        break;
      }
    }
    document.title = title;
  }, [location.pathname]);
  return (
    <Routes>
      <Route
        path="/signin"
        element={
          <SignIn
            isFullPage={true}
            imagePath="/assets/zibra-login-hero.webp"
            buttonStyle={{ backgroundColour: 'none', backgroundURL: '/assets/zibra-stripes.webp' }}
            imagePosition="bottom"
          />
        }
      />
      <Route
        path="/rentals/rental-agreement/form-submitted/:id"
        element={
          <AppContent
            pageName="Agreement-Complete"
            pageContent={<AgreementComplete backLink={(id) => `/movements/view/${id}`} />}
          />
        }
      />
      <Route
        path="/rentals/rental-agreement/:id/:bookingId"
        element={
          <AppContent
            pageContent={
              <CreateRentalAgreement AgreementDescription={<AgreementDescription />} backLink={(id) => `/movements/view/${id}`} />
            }
          />
        }
      />
      <Route
        path="/vehicle-handover/:id/"
        element={
          <AppContent pageContent={<VehicleHandover isVisible={true} AgreementDescription={<KAUAgreementDescription />} />} />
        }
      />
      <Route path="/movements" element={<AppContent pageName="Rentals-Request" pageContent={<Jobs />} />} />
      <Route path="/movements/view/:id" element={<AppContent pageContent={<JobDetails />} />} />
      <Route path="///" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
