type FilterConfig = {
  value: string[] | string;
  disabled: boolean;
  hidden: boolean;
};

export type InitialFilters = {
  [key: string]: FilterConfig;
  branchFilter: FilterConfig;
  jobStatusFilter: FilterConfig;
  dateFilter: FilterConfig;
  searchTerm: FilterConfig;
};

let initialFilters: InitialFilters = {
  branchFilter: { value: [], disabled: false, hidden: false },
  jobStatusFilter: { value: [], disabled: false, hidden: false },
  dateFilter: { value: [], disabled: false, hidden: false },
  driverFilter: { value: [], disabled: false, hidden: false },
  chaserFilter: { value: [], disabled: false, hidden: false },
  searchTerm: { value: '', disabled: false, hidden: false },
};

const getFilterConfig = (branchName: string | null) => {
  initialFilters = {
    ...initialFilters,
  };

  if (branchName) {
    return {
      ...initialFilters,
      branchFilter: {
        ...initialFilters.branchFilter,
        value: branchName,
        disabled: false,
      },
    };
  } else {
    return initialFilters;
  }
};

export default getFilterConfig;
