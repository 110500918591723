// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterByDate(item: any, selectedDateFilter: string) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  if (selectedDateFilter === 'TODAY') {
    // Filter for today
    const startOfDay = new Date(currentDate);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(currentDate);
    endOfDay.setHours(23, 59, 59, 999);

    return new Date(item.dueDate) >= startOfDay && new Date(item.dueDate) <= endOfDay;
  } else if (selectedDateFilter === 'TOMORROW') {
    // Filter for tomorrow
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
    return new Date(item.dueDate) >= tomorrow && new Date(item.dueDate) <= tomorrow;
  } else if (selectedDateFilter === 'YESTERDAY') {
    // Filter for yesterday
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    return new Date(item.dueDate) >= yesterday && new Date(item.dueDate) <= yesterday;
  } else if (selectedDateFilter === 'THIS_WEEK') {
    // Filter for this week
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay()));
    return new Date(item.dueDate) >= startOfWeek && new Date(item.dueDate) <= endOfWeek;
  } else if (selectedDateFilter === 'LAST_WEEK') {
    // Filter for last week
    const startOfLastWeek = new Date(currentDate);
    startOfLastWeek.setDate(currentDate.getDate() - currentDate.getDay() - 7);
    const endOfLastWeek = new Date(currentDate);
    endOfLastWeek.setDate(currentDate.getDate() - currentDate.getDay() - 1);
    return new Date(item.dueDate) >= startOfLastWeek && new Date(item.dueDate) <= endOfLastWeek;
  } else if (selectedDateFilter === 'NEXT_WEEK') {
    // Filter for next week
    const startOfNextWeek = new Date(currentDate);
    startOfNextWeek.setDate(currentDate.getDate() + (7 - currentDate.getDay()));
    const endOfNextWeek = new Date(currentDate);
    endOfNextWeek.setDate(currentDate.getDate() + (13 - currentDate.getDay()));
    return new Date(item.dueDate) >= startOfNextWeek && new Date(item.dueDate) <= endOfNextWeek;
  } else if (selectedDateFilter === 'THIS_MONTH') {
    // Filter for this month
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    return new Date(item.dueDate) >= firstDayOfMonth && new Date(item.dueDate) <= lastDayOfMonth;
  } else if (selectedDateFilter === 'LAST_MONTH') {
    // Filter for last month
    const firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const lastDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    return new Date(item.dueDate) >= firstDayOfLastMonth && new Date(item.dueDate) <= lastDayOfLastMonth;
  } else if (selectedDateFilter === 'NEXT_MONTH') {
    // Filter for next month
    const firstDayOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    const lastDayOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);
    return new Date(item.dueDate) >= firstDayOfNextMonth && new Date(item.dueDate) <= lastDayOfNextMonth;
  } else {
    // Default to no filter
    return true;
  }
}

export default filterByDate;
