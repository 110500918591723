import { useEffect, useState } from 'react';
import { Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import FilterTable from './FilterTable';
import { IJob } from '@scanny-app/loopy-loop';
interface DataTableProps<T> {
  columns: ColumnsType<T>;
  data: IJob[];
  columnsToSearch: string[];
  combinationColumns: [string, string][];
  className?: string;
  apiCompleted: boolean;
  searchPlaceholder: string;
  getRowKey: (record: T) => string | number;
}

const DataTable = <T extends object>({
  columns,
  data,
  columnsToSearch,
  combinationColumns,
  className,
  apiCompleted,
  searchPlaceholder,
  getRowKey,
}: DataTableProps<T>) => {
  const [dynamicY, setDynamicY] = useState(`calc(100vh - ${window.innerWidth <= 768 ? '400' : '300'}px)`);
  const [filteredData, setFilteredData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isFilterHidden, setIsFilterHidden] = useState(false);
  useEffect(() => {
    setIsLoading(!apiCompleted);
  }, [apiCompleted]);
  useEffect(() => {
    if (width <= 768 && isFilterHidden) {
      setDynamicY('calc(100vh - 200px)');
    } else if (width <= 768 && !isFilterHidden) {
      setDynamicY('calc(100vh - 400px)');
    } else {
      setDynamicY('calc(100vh - 300px)');
    }
  }, [width, isFilterHidden]);
  const handleFilteredData = (newFilteredData: []) => {
    setFilteredData(() => newFilteredData);
  };
  const handleOnHideChange = (isFilterHidden: boolean) => {
    setIsFilterHidden(isFilterHidden);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <FilterTable
        dataSource={data}
        onFilterChange={handleFilteredData}
        combinationColumns={combinationColumns}
        columnsToSearch={columnsToSearch}
        searchPlaceholder={searchPlaceholder}
        onHideChange={handleOnHideChange}
      />

      {isLoading && <Skeleton active />}
      <Table
        id="tb-rental-request"
        columns={columns}
        dataSource={filteredData}
        pagination={{ defaultPageSize: 20 }}
        scroll={{ x: '100%', y: dynamicY }}
        className={`${className} ${isLoading && 'hidden'}`}
        rowKey={getRowKey}
      />
    </>
  );
};

export default DataTable;
