import type { ColumnsType } from 'antd/es/table';
import { IJob, MyIcon, formatDate, formatTime, JobStatusComponent } from '@scanny-app/loopy-loop';
import { Tooltip } from 'antd';

const getColumnKeys = (isMobile: boolean) => {
  if (isMobile) {
    return ['mobileCols'];
  } else {
    return [
      'dueDate',
      'dueTime',
      'recordTypeName',
      'status',
      'customerName',
      'customerMobile',
      'branchName',
      'jobAddress',
      'vehicleName',
      'driverName',
      'chaserName',
      'jobNotes',
      'operation',
    ];
  }
};

const JOBS_DATA_COLUMNS = (navigate: (path: string) => void, isMobile: boolean): ColumnsType<IJob> => {
  const columnKeys: string[] = getColumnKeys(isMobile);

  const allColumns: ColumnsType<IJob> = [
    {
      title: 'Date',
      key: 'dueDate',
      width: 120,
      fixed: 'left',
      sorter: (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
      defaultSortOrder: 'descend',
      render: (record) => {
        const { weekday, dayMonthYear } = formatDate(record.dueDate);
        return (
          <div className={'flex flex-column'}>
            <div className={'font-semibold'}>{weekday}</div>
            <div>{dayMonthYear}</div>
          </div>
        );
      },
    },
    {
      title: 'Time',
      key: 'dueTime',
      width: 90,
      render: (record) => <span>{formatTime(record.dueDate).toUpperCase()}</span>,
      fixed: 'left',
    },
    {
      title: 'Type',
      key: 'recordTypeName',
      width: 140,
      render: (record) => <span>{record.recordTypeName}</span>,
    },
    {
      title: 'Status',
      key: 'status',
      width: 120,
      render: (record) => (
        <span>
          <JobStatusComponent status={record.status} />
        </span>
      ),
    },
    {
      title: 'Customer',
      key: 'customerName',
      width: 120,
      render: (record) => <div>{record.customerName ? record.customerName : '---'}</div>,
    },
    {
      title: 'Mobile',
      key: 'customerMobile',
      width: 120,
      render: (record) => (
        <div>
          {record.customerMobile ? (
            <a className={'text-black text-hover-green'} href={`tel:${record.customerMobile}`}>
              {record.customerMobile}
            </a>
          ) : (
            '---'
          )}
        </div>
      ),
    },

    {
      title: 'Branch',
      key: 'branchName',
      width: 90,
      render: (record) => <div>{record.branchName}</div>,
    },
    {
      title: 'Address',
      key: 'jobAddress',
      width: 160,
      render: (record) => (
        <Tooltip placement="topLeft" title={record.jobAddress}>
          {record.jobAddress ? (
            <a
              className={'text-black text-hover-green flex gap-8 icon-sm'}
              href={`https://maps.google.com/?q=${encodeURIComponent(record.jobAddress)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={'flex flex-row gap-8'}>
                <div className={'w-fit mt-2'}>
                  <MyIcon icon={'IconLocation'} />
                </div>
                <div className={'line-clamp-2'}>{record.jobAddress}</div>
              </div>
            </a>
          ) : (
            '---'
          )}
        </Tooltip>
      ),
    },

    {
      title: 'Vehicle',
      key: 'vehicleName',
      width: 90,
      render: (record) => (
        <Tooltip placement="topLeft" title={`${record.vehicleMakeName} ${record.vehicleModelName}`}>
          {record.vehicleName}
        </Tooltip>
      ),
    },
    {
      title: 'Driver',
      key: 'driverName',
      width: 120,
      render: (record) => <div>{record.driverName ? record.driverName : '---'}</div>,
    },
    {
      title: 'Chaser',
      key: 'chaserName',
      width: 120,
      render: (record) => <div>{record.chaserName ? record.chaserName : '---'}</div>,
    },
    {
      title: 'Notes',
      key: 'jobNotes',
      width: 160,
      render: (record) => <div>{record.jobNotes}</div>,
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record) => (
        <button
          onClick={() => navigate(`/movements/view/${record.id}`)}
          className="btn-action-table content-center w-full h-full"
        >
          <MyIcon icon="IconNext" />
        </button>
      ),
    },
    {
      title: 'Display Data',
      key: 'mobileCols',
      sortOrder: 'descend',
      sorter: (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
      render: (record) => {
        const { weekday, dayMonthYear } = formatDate(record.dueDate);

        return (
          <div className="flex flex-row">
            <div style={{ width: 'calc(100% - 28px)' }}>
              <div className="flex border-bottom-dashed-grey content-space-between middle  p-8-0">
                <div className="flex flex-row gap-4 icon-ms middle">
                  <div className={'font-semibold'}>{weekday},</div>
                  <div>{dayMonthYear}</div>
                </div>
                <div>{formatTime(record.dueDate).toUpperCase()}</div>
              </div>
              <div className="flex border-bottom-dashed-grey content-space-between middle  p-8-0">
                <div className="flex gap-8 icon-ms middle">
                  <MyIcon icon="IconCustomer" />
                  {record.customerName ? <h5 className="text-xs font-semibold m-0">{record.customerName}</h5> : '---'}
                </div>
                <JobStatusComponent status={record.status} />
              </div>
              <div className="flex border-bottom-dashed-grey content-space-between gap-8 middle p-8-0 border-y">
                <div className="truncate md-gap-8 icon-ms inline-flex w-60">
                  {record.jobAddress ? (
                    <a
                      className={'text-black text-hover-green flex gap-8 icon-sm'}
                      href={`https://maps.google.com/?q=${encodeURIComponent(record.jobAddress)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={'flex flex-row gap-8'}>
                        <div className={'w-fit mt-2'}>
                          <MyIcon icon="IconAddress" />
                        </div>
                        <div className={'line-clamp-3'}>{record.jobAddress}</div>
                      </div>
                    </a>
                  ) : (
                    '---'
                  )}
                </div>
                <div className="inline-flex">
                  {record.customerMobile ? (
                    <a className={'text-black text-hover-green'} href={`tel:${record.customerMobile}`}>
                      {record.customerMobile}
                    </a>
                  ) : (
                    '---'
                  )}
                </div>
              </div>
              <div className="flex flex-column p-8-0 gap-8">
                <div className="flex content-space-between w-full">
                  <div className="text-xxs text-grey middle flex gap-4">
                    <div style={{ width: 36 }} className="icon-xs ">
                      <MyIcon icon={'IconDriver'} />
                    </div>
                    {record.driverName ? record.driverName : '---'}
                  </div>
                  <div className="text-xxs text-grey">{record.vehicleName ? record.vehicleName : '---'}</div>
                </div>
                <div className="flex content-space-between w-full">
                  <div className="text-xxs text-grey middle flex gap-4">
                    <div style={{ width: 36 }} className="icon-xs ">
                      <MyIcon icon={'IconChaserVehicle'} />
                    </div>
                    {record.chaserName ? record.chaserName : '---'}
                  </div>
                  <div className="text-xxs text-grey">{record.recordTypeName}</div>
                </div>
              </div>
            </div>
            <div style={{ width: '28px' }}>
              <div onClick={() => navigate(`/movements/view/${record.id}`)} className="content-end w-full h-full flex middle">
                <MyIcon icon="IconNext" />
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  return allColumns.filter((column) => column.key && columnKeys.includes(column.key as string));
};
export { JOBS_DATA_COLUMNS };
