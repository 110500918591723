import { ISelectOption } from '@scanny-app/loopy-loop';

export type TimeZone =
  | 'Australia/Lord_Howe'
  | 'Australia/Hobart'
  | 'Australia/Currie'
  | 'Australia/Melbourne'
  | 'Australia/Sydney'
  | 'Australia/Broken_Hill'
  | 'Australia/Brisbane'
  | 'Australia/Lindeman'
  | 'Australia/Adelaide'
  | 'Australia/Darwin'
  | 'Australia/Perth'
  | 'Australia/Eucla';

export const timeZoneOptions: ISelectOption[] = [
  {
    label: 'Australia/Hobart',
    value: 'Australia/Hobart',
  },
  {
    label: 'Australia/Melbourne',
    value: 'Australia/Melbourne',
  },
  {
    label: 'Australia/Sydney',
    value: 'Australia/Sydney',
  },
  {
    label: 'Australia/Broken_Hill',
    value: 'Australia/Broken_Hill',
  },
  {
    label: 'Australia/Brisbane',
    value: 'Australia/Brisbane',
  },
  {
    label: 'Australia/Adelaide',
    value: 'Australia/Adelaide',
  },
  {
    label: 'Australia/Darwin',
    value: 'Australia/Darwin',
  },
  {
    label: 'Australia/Perth',
    value: 'Australia/Perth',
  },
];
