import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { IJob, IBooking, LargeLoading, SecondaryNavigation, formatISO } from '@scanny-app/loopy-loop';
import { Image, message } from 'antd';
import JobDetails from '../../components/ViewRecord/JobDetails';
import dayjs from 'dayjs';
function ViewRecord() {
  const [jobData, setJobData] = useState<IJob>({} as IJob);
  const [bookingData, setBookingData] = useState<IBooking>({} as IBooking);
  const [getDataError, setGetDataError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [dataToSubmit, setDataToSubmit] = useState({} as IJob);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchJob = async () => {
      try {
        const sessionData = await Auth.currentSession();
        const token = sessionData.getAccessToken().getJwtToken();

        const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Job`, JSON.stringify({ id: id }), {
          headers: {
            Authorization: token,
          },
        });
        if (response.data[0].bookingId) {
          try {
            const bookingResponse = await axios.post(
              `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Booking`,
              JSON.stringify({ id: response.data[0].bookingId }),
              {
                headers: {
                  Authorization: token,
                },
              },
            );
            setBookingData(bookingResponse.data[0]);
          } catch (error) {
            console.error('Error fetching booking data:');
          }
        }
        setJobData({
          ...response.data[0],
          jobTime: formatISO(response.data[0].dueDate).split(', ')[1].toUpperCase(),
          jobDate: dayjs(formatISO(response.data[0].dueDate).split(', ')[0], 'DD/MM/YYYY'),
          vehicle: `${response.data[0].vehicleName} - ${response.data[0].vehicleMakeName} ${response.data[0].vehicleModelName}`,
        });
      } catch (error) {
        setGetDataError('Job not found');
      }
      setIsLoading(false);
    };

    fetchJob();
  }, []);

  const handleSubmit = () => {
    setIsUpdating(true);
    Auth.currentSession().then((sessionData) => {
      const token = sessionData.getAccessToken().getJwtToken();
      axios
        .post(`${process.env.REACT_APP_ENDPOINT_URL}/update/Job/${id}`, JSON.stringify(dataToSubmit), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          message.success('Job updated successfully');
          setIsUpdating(false);
          setIsEditMode(false);
        })
        .catch(() => {
          message.error('Error updating job');
        });
    });
  };
  const handleDataChange = (formData: IJob) => {
    setDataToSubmit(formData);
  };
  const onBack = () => navigate(`/movements`);
  const leftNode = getDataError ? '' : `${jobData.recordTypeName} - ${jobData.customerName}`;
  const rightNode = isEditMode ? (
    <>
      <button onClick={() => setIsEditMode(false)} className="delete-btn cust-btn">
        Cancel
      </button>
      <button onClick={handleSubmit} className="btn-secondary align-middle">
        Submit
      </button>
    </>
  ) : (
    <button onClick={() => setIsEditMode(true)} className="btn-secondary align-middle">
      Edit
    </button>
  );

  if (isLoading) {
    return <LargeLoading />;
  }
  if (getDataError) {
    return (
      <div className="container-2 p-24 flex flex-column content-center middle gap-16">
        <Image className="" height={100} preview={false} src="/assets/es-error.svg"></Image>
        <h1 className="heading-page-content">{getDataError}</h1>
        <a className="no-underline" href="/rentals/rental-requests">
          <button className="btn-secondary flex middle"> Back to rental requests</button>
        </a>
      </div>
    );
  }
  return (
    <>
      {isUpdating && <LargeLoading customText={'Updating the job...'} />}
      <SecondaryNavigation menuTitle={leftNode} rightNode={rightNode} onBack={onBack} borderBottom={true} />
      <JobDetails
        jobData={jobData}
        bookingData={bookingData}
        componentDisabled={!isEditMode}
        handleDataChange={handleDataChange}
      />
    </>
  );
}

export default ViewRecord;
